import { Roboto } from "next/font/google";
import "../i18n/config";
import Head from "next/head";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { getURLValue } from "@/app/Components/Common";

const roboto = Roboto({
  weight: ["100", "300", "400", "500", "700", "900"],
  subsets: ["latin"],
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const checkSessionStatus = async () => {
    const url = router.pathname;
    const apiurl =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/validateusersession";

    const res = await fetch(apiurl, {
      method: "GET",
      credentials: "include",
    });
    const sessionStatus = await res.text();
    //validate
    if (
      sessionStatus == "valid" &&
      !(
        url.includes("/interactivestories") ||
        url.includes("/ces") ||
        url.includes("/ces-2024") ||
        url.includes("/ces-2025") ||
        url.includes("/CES-Company-Profiles") ||
        url.includes("/CES2025Reports") ||
        url.includes("/viewcesreport") ||
        (url.includes("R1VFU1RMT0dJTg==") && url.includes("designid")) ||
        url.includes("/uspowerplantmap-japaneseinvestment") ||
        url.includes("/enrich") || 
        url.includes("/elite") || 
        url.includes("/Schedule-Demo")
      )
    ) {
      const navigateUrl = router?.query?.url && getURLValue("url");

      if (navigateUrl) {
        if (navigateUrl.startsWith(process.env.NEXT_PUBLIC_SERVER_NAME_URL)) {
          window.location.href = navigateUrl;
        } else {
          window.location.href =
            process.env.NEXT_PUBLIC_SERVER_NAME_URL + navigateUrl;
        }
      } else {
        window.location.href =
          process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/dashboard";
      }
    }
  };

  useEffect(() => {
    if (!router?.isReady) {
      return;
    }
    const url = router.pathname;
    if (
      [
        "Get-Started.html",
        "AI-Research-Tools.html",
        "/CES2024Reports",
        "/ces-2023",
      ].some((path) => url.includes(path))
    ) {
      router.push(`${process.env.NEXT_PUBLIC_SERVER_NAME_URL}/dashboard`);
    }
    checkSessionStatus();
  }, [router?.isReady]);

  return (
    <main className={roboto.className}>
      {
        <Head>
          {process.env.NEXT_PUBLIC_SERVER == "production" && (
            <script
              dangerouslySetInnerHTML={{
                __html: `(function() {
              var chklocation = document.location.href;
              // https://test.ideapoke.com/
              if (chklocation.indexOf("test.ideapoke.com") !== -1||chklocation.indexOf("jp.ideapoke.com") !== -1) {
              console.log("test123")
              }
              else if (chklocation.indexOf("ideapoke.com") !== -1 && chklocation.indexOf("www.ideapoke.com") === -1) {
                var referencesubdomain = "www.ideapoke.com";
                if (chklocation.indexOf("www.") < 0) {
                  var weburlval = chklocation.replace("https://", "");
                  document.location.href = "https://www." + weburlval;
                }
              }
            })();
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:2534714,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
              }}
            />
          )}
        </Head>
      }
      <Component {...pageProps} />
    </main>
  );
}

export default MyApp;
